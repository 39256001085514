
  import { IEnrollment, IMaterialsDownload } from "@/interfaces";
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import { formatDate, formatTimeRange } from "@/date-utils";
  import { apiUrl } from "@/env";
  import { api } from "@/api";
  import { dispatchCheckApiError } from "@/store/main/actions";
  import { readToken } from "@/store/main/getters";
  import { commitAddNotification } from "@/store/main/mutations";

  @Component
  export default class CourseCard extends Vue {
    @Prop({ required: true }) public enrollment!: IEnrollment;
    @Prop({ default: false }) public detailed!: boolean;
    materialsDownload: IMaterialsDownload | null = null;
    downloadAvailableTime: Date | null = null;
    downloadExpiryTime: Date | null = null;
    downloadExpired = false;
    dialog = false;
    code: string | null = null;
    loading = false;
    private showDetails = false;

    get event() {
      return this.enrollment ? this.enrollment.event : null;
    }

    get date() {
      if (this.event && this.event.start_date && this.event.utc_offset) {
        return formatDate(this.event.start_date, this.event.utc_offset);
      }
      return "";
    }

    get time() {
      if (
        this.event &&
        this.event.start_date &&
        this.event.end_date &&
        this.event.utc_offset
      ) {
        return formatTimeRange(
          this.event.start_date,
          this.event.end_date,
          this.event.utc_offset,
        );
      }
      return "";
    }

    get downloadUrl() {
      return `${apiUrl}/api/v1/material_downloads/download/?event_id=${this.event?.id}`;
    }

    get isPast() {
      if (this.event) {
        return new Date().getTime() > new Date(this.event.end_date).getTime();
      }
      return null;
    }

    downloadMaterials() {
      this.dialog = false;
      setTimeout(this.loadDetails, 3000);
      window.open(
        `${apiUrl}/api/v1/material_downloads/download/?event_id=${this.event?.id}`,
        "_blank",
      );
    }

    formatDate(date: Date) {
      return new Intl.DateTimeFormat([], {
        dateStyle: "full",
        timeStyle: "long",
      }).format(date);
    }

    toggleShowDetails() {
      this.showDetails = !this.showDetails;
    }

    async onSubmit() {
      if (!this.code || !this.event) {
        return;
      }

      try {
        this.loading = true;
        const token = readToken(this.$store);
        const resp = await api.registerThinkific(token, {
          event_id: this.event.id,
          code: this.code,
        });
        window.location.href = resp.data.url;
        this.loading = false;
      } catch (error) {
        this.loading = false;

        commitAddNotification(this.$store, {
          content: "Incorrect post test code",
          color: "error",
        });
        dispatchCheckApiError(this.$store, error);
      }
    }

    async loadDetails() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getMaterialsDownload(token, this.enrollment.event_id);
        this.materialsDownload = resp.data;
      } catch (error) {
        this.materialsDownload = null;
        dispatchCheckApiError(this.$store, error);
      }

      const effectiveDate =
        this.materialsDownload && this.materialsDownload.effective_date
          ? new Date(this.materialsDownload.effective_date)
          : null;
      const now = new Date();
      if (effectiveDate && now.getTime() < effectiveDate.getTime()) {
        this.downloadAvailableTime = effectiveDate;
      } else {
        this.downloadAvailableTime = null;
      }

      this.downloadExpiryTime =
        this.materialsDownload && this.materialsDownload.expiration_date
          ? new Date(this.materialsDownload.expiration_date)
          : null;
      this.downloadExpired =
        this.downloadExpiryTime !== null &&
        now.getTime() >= this.downloadExpiryTime.getTime();
    }

    @Watch("showDetails")
    async onShowDetailsChanged(newShowDetails: boolean) {
      if (newShowDetails) {
        await this.loadDetails();
      }
    }

    async goToThinkific() {
      const token = readToken(this.$store);
      try {
        this.loading = true;
        const resp = await api.getThinkificSSOUrl(
          token,
          this.enrollment.test_completed_date ? true : false,
          false,
        );
        window.location.href = resp.data.url;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        dispatchCheckApiError(this.$store, error);
      }
    }

    async mounted() {
      this.showDetails = this.detailed;
      if (this.showDetails) {
        await this.loadDetails();
      }
    }
  }
